import React, { useEffect, useState, useCallback, useRef } from 'react';
import './Input.css';
import Button from '../button';

function Input(props) {

    useEffect(() => {
        if(props.onValidate) {
            props.onValidate({
                name: props.name,
                isValid: false,
            });
        }
    }, []);

    const handlerOnChange = (event) => {
        if(props.onChange) {
            props.onChange(event, event.target.value, props.name);
        }
        if(props.onValidate) {
            props.onValidate({
                name: props.name,
                isValid: !!event.target.value,
            });
        }
    }

    var className = '';
    if(props.iconClass) {
        className = 'with-icon';
    }
    if(props.validation && props.validation.needValidation && !props.validation.fields[props.name]) {
        className += ' not-valid';
    }

    return (
    <div className="input">
        {props.iconClass &&
            <span className={"icon " + props.iconClass}></span>
        }
        <input className={className} type="text" 
            placeholder={props.placeholder} 
            name={props.name}
            defaultValue={props.value}
            onChange={event => handlerOnChange(event)} />
    </div>
    );
}

function FileUpload(props) {
    const hiddenFileInput = useRef(null);
    const [fileNames, setFilesNames] = useState(null);
    const [files, setFiles] = useState(null);

    useEffect(() => {
        if(props.files != files) {
            setFiles(props.files);
            if(props.files != null && Array.from(props.files).length > 0) {
                setFilesNames(Array.from(props.files).map(file => file.name).join(', '));
            }
            else {
                setFilesNames(null);
            }
        }
    }, [props.files]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleFile = useCallback(e => {
        setFilesNames(Array.from(e.target.files).map(file => file.name).join(', '));
        setFiles(e.target.files);
        if(props.onChange) {
            props.onChange(e, e.target.files);
        }
    }, [])

    return (
        <React.Fragment>
            <div className="file-upload">
                <Button width="auto" text="Choose file" disabled={props.disabled} onClick={handleClick}></Button>
                <input type="file" ref={hiddenFileInput} onChange={handleFile} accept={props.accept} hidden />
                <span className="file-names">{fileNames}</span>
            </div>
        </React.Fragment>
    );
}

export default Input;
export { FileUpload }
