import React, { useEffect, useState, useRef, } from 'react';
import './Select.css';
import { Select as SelectMaterial, MenuItem } from '@mui/material';

function Select(props) {
    const [dataSourceItems, setDataSourceItems] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const isInitRender = useRef(true);

    useEffect(() => {
        loadItems();
        if(!isInitRender.current && props.value !== selectedValue) {
            setSelectedValue(props.value);
        }
        isInitRender.current = false;
        if(props.onValidate) {
            props.onValidate({
                name: props.name,
                isValid: false,
            });
        }
    }, [props.value]);

    function loadItems() {
        if(!props.dataSource) {
            setSelectedValue(props.value);
            return;
        }

        const result = props.dataSource();

        if(result instanceof Promise) {
            result.then(items => {
                setDataSourceItems(items);
                setSelectedValue(props.value);
            });
        }
        else {
            setDataSourceItems(result);
            setSelectedValue(props.value);
        }
    }

    function getItems() {
        if(props.dataSource) {
            return dataSourceItems;
        }
        return props.items;
    }

    const handlerOnChange = (event) => {
        if(props.onChange) {
            props.onChange(event, event.target.value, props.name);
        }
        if(props.onValidate) {
            props.onValidate({
                name: props.name,
                isValid: !!event.target.value,
            });
        }
    }

    var className = 'select';
    if(props.validation && props.validation.needValidation && !props.validation.fields[props.name]) {
        className += ' not-valid';
    }

    return (
    <div className="select-container">
        <SelectMaterial 
            sx={{
                "& fieldset": {
                    border: "none",
                },
            }}
            value={selectedValue} 
            className={className}
            onChange={(event) => handlerOnChange(event)}>
            {getItems() &&
                getItems().map((item, index) => <MenuItem key={index} value={item.key}>{item.value}</MenuItem>)
            }
        </SelectMaterial>
    </div>
    );
}

export default Select;
