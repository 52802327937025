class FormValidator {
    
    constructor() {
        this.needValidation = false;
        this.fields = {};
    }

    static init() {
        return {
            needValidation: false,
            fields: {},
        };
    }

    static startValidation(validator, setState) {
        validator.needValidation = true;
        setState(Object.assign({}, validator));
    }

    static isValid(validator) {
        return !Object.keys(validator.fields).map(field => validator.fields[field]).some(x=> x == false);
    }

    static setField(validator, field, setState) {
        validator.fields[field.name] = field.isValid;
        setState(Object.assign({}, validator));
    }
}

export default FormValidator;