import './Form.css';
import FormValidator from './FormValidator.js';

function Form(props) {
    return (
        <div className={'form ' + props.className}>
            {props.children}
        </div>
    );
}

function FormRow(props) {
    return (
        <div className="form-row">
            {props.children}
        </div>
    );
}

function FormLabel(props) {
    return (
        <div className="form-label">
            <label >
                {props.children}
            </label>
        </div>
    );
}

function FormInput(props) {
    return (
        <div className="form-input">
            {props.children}
        </div>
    );
}

function FormActionButton(props) {
    return (
        <div className="action-button">
            {props.children}
        </div>
    );
}



export {
    Form,
    FormRow,
    FormLabel,
    FormInput,
    FormActionButton,
    FormValidator,
}