import './Login.css'
import Panel from '../../components/panel';
import Button from '../../components/button';
import { useMsal } from "@azure/msal-react";
import Auth from '../../utils/auth';
import { loginRequest } from '../../utils/authConfig';
import { useState } from 'react';
import Alert from '../../components/alert';

function Login() {
  const msal = useMsal();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  function handlerLogin() {
    msal.instance.loginRedirect(loginRequest)
    .then(responce => {
      Auth.init(msal)
    })
    .catch(e => {
        setAlertMessage('Something went wrong, please reload the page and try again');
        console.log(e);
    });
  };

  return (
    <div className="login-container">
      <div className="login">
        <Panel>
          <div className="login-panel">
            <Alert isOpen={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage}</Alert>
            <div className="login-grid">
              <div className="logo"></div>
              <div className="login-title">
                <h1>Welcome!</h1>
                <div className="title">Please login in to your account</div>
              </div>
              <div className="login-button">
                <Button text="Sign in with Microsoft" onClick={() => handlerLogin()}>
                  <div className="ms-icon login-icon"></div>
                </Button>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
}

export default Login;
