import './Chart.css';
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from 'chart.js';
import Skeleton from '../../components/skeleton';
import Loading from '../../components/loading';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);

function Chart(props) {
  
  const [dataChart, setDataChart] = useState(null);

  useEffect(() => {
    if(!props.data) {
      return;
    }
    const labels = props.data.labels;
    setDataChart({
      labels,
      datasets: [
        {
          data: props.data.points,
          borderColor: '#078144',
          backgroundColor: 'rgba(218, 233, 191, 0.2)',
          tension: 0.0,
        },
      ],
    });
  }, [props.data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
    },
    elements: {
      line: {
          tension: 0.4
      },
    },
    scales: {
      y: {
        suggestedMin: props.options?.min,
        suggestedMax: props.options?.max,
      },
    },
  };

  const defaultWidth = 750;
  const defaultHeight = 347;

  return (
    <div className="chart-container">
      {props.loading ?
          <Loading></Loading>
        : <div className="loading-space"></div>
      }
      <div className="chart-background">
        {dataChart == null ?
          <Skeleton width={defaultWidth} height={defaultHeight}></Skeleton>
          : <Line options={options} data={dataChart ?? {}} width={defaultWidth} height={defaultHeight} />
        }
      </div>
    </div>
  )
}

export default Chart;