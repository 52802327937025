import './Profile.css';
import React, { useState, useRef, useEffect } from 'react';
import Button, { IconButton } from '../button';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Auth from '../../utils/auth';
import Select from '../select';
import Skeleton from '../skeleton';

function Profile(props) {
    const navigate = useNavigate();
    const [farmName, setFarmName] = useState(Auth.getFarmName());
    const { instance, accounts } = useMsal();
    const ref = useRef(null);
    const [profilePopupIsVisible, setProfilePopupIsVisible] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setProfilePopupIsVisible(false);
        }
    };

    const handlerLogout = () => {
        navigate("/logout");
        Auth.logout();
    }

    const handlerChangeFarm = (event, value) => {
        setFarmName(value);
        Auth.loadFarm(value);
        if(props.handlerFarmChange) {
            props.handlerFarmChange({
                farmName: value,
            });
        };
    }

    return (
        <React.Fragment>
            <div className="profile" >
                <div className="more-info" ref={ref}>
                    <IconButton iconClass={'more-info-icon'} 
                        onClick={() => setProfilePopupIsVisible(true)}></IconButton>
                    {profilePopupIsVisible &&
                        <React.Fragment>
                            <div className="profile-menu">
                                <Button type="light" text='Logout' onClick={ handlerLogout }>
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className="user-name">{accounts ? accounts[0]?.name : ''}</div>
                <div className="empty"></div>
                <div>
                    {Auth.isAdmin() ?
                        <div className="select-farm">
                            {Auth.farmsIsLoading() === null ?
                                <Skeleton width='100%' height='80px'></Skeleton>
                                : <Select placeholder="Farm" 
                                    value={farmName} 
                                    onChange={(event, value) => handlerChangeFarm(event, value) }
                                    dataSource={Auth.getFarms}></Select>
                            }
                        </div>
                        : Auth.getFarmName()
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Profile;