import HttpClient from "../../utils/httpClient"
import Auth from "../../utils/auth";
import dayjs from 'dayjs';

class BenefitsService {
    
    static getStatus() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/benefits`);
    }
}

export default BenefitsService;