import Panel from '../../components/panel';
import Button from '../../components/button';
import { useNavigate } from "react-router-dom";
import Auth from '../../utils/auth';

function DepartmentError() {
  const navigate = useNavigate();

  const handlerLogout = () => {
    navigate("/logout");
    Auth.logout();
  }


  return (
    <div className="login-container">
      <div className="login">
        <Panel>
          <div className="login-grid">
            <div className="logo"></div>
            <div className="login-title">
              <h1>Sorry...</h1>
              <div className="title">Department has not been identified</div>
              <div className="title">Contact the administrator</div>
            </div>
            <div className="login-button">
              <Button text="Logout" onClick={() => handlerLogout()}>
              </Button>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
}

export default DepartmentError;
