import './Menu.css';
import { 
    useLocation, 
    Link, 
} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Auth from '../../utils/auth';

const getMenuList = (farm) => [
    {
        name: 'Dashboard',
        link: '/',
        iconClass: 'dashboard-icon',
        visible: true,
    },
    {
        name: 'Documentation',
        link: '/documentation',
        iconClass: 'documentation-icon',
        visible: true,
    },
    {
        name: 'Benefits',
        link: '/benefits',
        iconClass: 'benefits-icon',
        visible: !farm.hideBenefits,
    },
];

function Menu(props) {
    const location = useLocation();  
    const [menu, setMenu] = useState(getMenuList(Auth.getFarm()));

    useEffect(() => {
        setMenu(getMenuList(Auth.getFarm()));
    }, [props.farmName]);

    return (
        <div className="vertical-menu">
            <ul>
                {   
                    menu.filter(x=> x.visible)
                        .map((menu, index) => 
                        <li key={index} 
                            className={"menu-item " + (location.pathname == menu.link ? "active" : "")}>
                            <span className={"menu-icon " + menu.iconClass}></span>
                            <Link to={menu.link}>{menu.name}</Link>
                        </li>)
                }
            </ul>
        </div>
    );
}

export default Menu;
