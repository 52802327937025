import './Modal.css';
import React, { useEffect, useState } from 'react';
import { IconButton } from '../button';
import ReactModal from 'react-modal';

function Modal(props) {
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        if (props.open !== open) {
            if(props.open) {
                document.getElementById('root').classList.add("active-modal");
                document.getElementById('body').classList.add("hidden-scroll");
            }
            else {
                document.getElementById('root').classList.remove("active-modal");
                document.getElementById('body').classList.remove("hidden-scroll");
            }
            setOpen(props.open);
        }
    }, [props.open]);
    
    const handlerOnClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    return (<React.Fragment>
        <ReactModal isOpen={open} appElement={document.getElementById('root')}>
            {open &&
                <div className="pop-up">
                    <div className="pop-up-top">
                        <div className="pop-up-title">{props.title}</div>
                        {props.showCloseButton != false &&
                            <IconButton iconClass="pop-up-close" 
                                onClick={handlerOnClose}></IconButton>
                        }
                    </div>
                    <div className="scroll-container">
                        <div className="pop-up-content">
                            {props.children}
                        </div>
                    </div>
                </div>
            }
        </ReactModal>
    </React.Fragment>);
}

export default Modal;