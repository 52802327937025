import React, { useState, useEffect, } from 'react';
import { Alert as AlertMaterial } from '@mui/material';
import Collapse from '@mui/material/Collapse';

function Alert(props) {
    const [open, setOpen] = useState(false);
    const autoCloseAlertInMs = 2000;

    useEffect(() => {
        if(props.isOpen != open) {
            setOpen(props.isOpen);
            if(props.isOpen) {
                setTimeout(() => {
                    if(props.onClose) {
                        props.onClose();
                    }
                }, autoCloseAlertInMs);
            }
        }
    }, [props.isOpen]);

  return (
    <React.Fragment>
        <Collapse in={open}>
            <AlertMaterial className={props.className}
                severity={props.severity ?? 'error'}
                onClose={props.onClose}>
                {props.children}
            </AlertMaterial>
        </Collapse>
    </React.Fragment>
    );
}

export default Alert;
