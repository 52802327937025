import React, { useState, useEffect } from 'react';
import Layout from './components/layout';
import Dashboard from './pages/dashboard';
import Documentation from './pages/documentation';
import Benefits from './pages/benefits';
import Login from './pages/login';
import Logout from './pages/logout';
import NotFound from './pages/notFound';
import { HashRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Auth from './utils/auth';
import DepartmentError from './pages/departmentError';
import Loading from './components/loading';

function App() {
  const msal = useMsal();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if(msal.accounts.length > 0) {
        await Auth.init(msal);
      }
      await msal.instance
              .handleRedirectPromise()
              .then(async (account) => {
                if(account) {
                  await Auth.initAccount(msal.instance, account);
                }
              })
              .catch((error) => {
                console.log(error);
              });
      setLoading(false);
    }

    init();
  }, []);


  return (
    <React.Fragment>
      {loading && 
        <Loading></Loading>
      }
      {!loading &&
        <HashRouter>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="logout" element={<Logout />} />
              {!Auth.getFarm() ?
                <Route path="*" element={<DepartmentError />}></Route> :
                <React.Fragment>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="documentation" element={<Documentation />} />
                    {!Auth.getFarm().hideBenefits &&
                      <Route path="benefits" element={<Benefits />} />}
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </React.Fragment>
              }
            </Routes>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Routes>
              <Route path="logout" element={<Logout />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </UnauthenticatedTemplate>
        </HashRouter>
      }
    </React.Fragment>
  );
}

export default App;
