import HttpClient from "../../utils/httpClient"
import ReportHelper from "../../utils/reportHelper"
import Auth from "../../utils/auth";

class DocumentationService {

    static getDocumentTypeList(searchParams) {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/folder`, searchParams).then(response => {
            return response.map(element => { return {name: element}});
        });
    }

    static getDocumentList(folderName) {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/folder/${folderName}`)
            .then(response => {
                return response.map((file, index) => {
                    //let lastDotIndex = file.name.lastIndexOf(".");
                    let ext = "pdf";// file.name.substring(lastDotIndex + 1);
                    return {
                        ...file,
                        id: index, 
                        extension: ext,
                    };
                });
            });
    }

    static getDocument(folderName, fileName) {
        return HttpClient.getBlob(`site-api/farm/${Auth.getFarmName()}/${folderName}/filename/${fileName}`)
            .then(blob => 
                ReportHelper.getFile(blob, fileName));
    }

    static addFolder(folderName){
        return HttpClient.post(`integration-api/farm/${Auth.getFarmName()}/folder/${folderName}`);
    }

    static deleteFolder(folderName){
        return HttpClient.delete(`integration-api/farm/${Auth.getFarmName()}/folder/${folderName}`);
    }

    static addDocument(folderName, file){
        let formData = new FormData();
        formData.append("file", file);
        return HttpClient.post(`integration-api/farm/${Auth.getFarmName()}/folder/${folderName}/addfile`, formData);
    }
    
    static deleteDocument(folderName, fileName){
        return HttpClient.delete(`integration-api/farm/${Auth.getFarmName()}/folder/${folderName}/file/${fileName}`);
    }
}

export default DocumentationService;