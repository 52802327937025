import fetchIntercept from 'fetch-intercept';
import Auth from './auth';


const handleRequest = async (url, options) => {
    if(url.startsWith(window.config.apiUrl)) {
      const token = await Auth.getToken();
      if (token) {
          const headers = {
            Authorization: `Bearer ${token}`
          };
          options.headers = headers;
      }
    }
    return [url, options];
};

const unregister = fetchIntercept.register({
    request: handleRequest,
});