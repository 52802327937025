class ReportHelper {
    static getFile(blob, fileName) {
        const a = document.createElement("a")
        document.body.appendChild(a)
        a.style = "display: none"
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        a.click()
    }
}

export default ReportHelper;