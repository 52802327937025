import './ProgressBar.css';

function preparValue(value) {
  var result = parseFloat(value);
  if(!result || isNaN(result)) {
    result = 0;
  }
  if(result > 100) {
    result = 100;
  }

  return result;
}

function ProgressBar(props) {
  return (
    <div className="progress-bar">
        <div className="progress-bar-value" style={{width: preparValue(props.value).toString() + '%'}}></div>
    </div>
  );
}

export default ProgressBar;
