import { loginRequest } from './authConfig';
import HttpClient from './httpClient';

class Auth {
    static groups = [];
    static isAuth = false;
    static msal = null;
    static farm = null;
    static farms = null;

    static init(msal) {
        this.msal = msal;
        return msal.instance
            .acquireTokenSilent({
                ...loginRequest,
                account: msal.accounts[0],
            })
            .then(async(response) => {
                this.isAuth = true;
                await this.loadFarm(response.account.idTokenClaims.department);
                this.groups = response.account.idTokenClaims.groups;
            })
            .catch(error => {
                console.log(error);
                Auth.logout();
            });
    }

    static async initAccount(instance, account) {
        this.msal = { instance, accounts: [account] };
        this.isAuth = true;
        await this.loadFarm(account.idTokenClaims.department);
        this.groups = account.idTokenClaims.groups;
    }

    static getIsAuth() {
        return this.isAuth;
    }

    static async getToken() {
        var response = await this.msal.instance.acquireTokenSilent({
            ...loginRequest,
            account: this.msal.accounts[0],
        });

        return response.accessToken;
    }

    static logout() {
        this.msal.instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/"
        });
        this.msal = null;
        this.groups = [];
        this.isAuth = false;
    }

    static getFarmName() {
        return this.farm?.name;
    }

    static getFarm() {
        return this.farm;
    }

    static isAdmin() {
        return window.config.adminGroups.filter(value => this.groups.includes(value)).length > 0;
    }

    static async loadFarm(farmName) {
        if(Auth.farms === null || this.farms.length === 0 ) {
            this.farms = await HttpClient.get('site-api/farms');
        }
        this.farm = Auth.farms.find(farm => farm.name === farmName);
    }
    
    static farmsIsLoading() {
        return !this.farms;
    }

    static getFarms() {
        return HttpClient.get(`site-api/farms`).then(response => {
            return response.map((element, index) => {
                return {key: element.name, value: element.name};
            });
        });
    }
}

export default Auth;