import './Button.css';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Button(props) {

  var className = props.type ?? 'default';
  if(!props.shadow) {
    className += ' without-shadow';
  }
  if(props.className) {
    className += ' ' + props.className;
  }
  if(props.width) {
    className += ' ' + props.width;
  }
  if(props.loading) {
    className += ' button-blur';
  }
  if(props.active) {
    className += ' active';
  }

  return (
    <React.Fragment>
      <span style={{position: 'relative'}}>
        <button className={className} 
                disabled={props.disabled || props.loading}
                onClick={props.onClick}>
            {props.children}
            {props.text}
        </button>
        {props.loading &&
          <CircularProgress className="button-loading" size={24} color="success" />
        }
      </span>
    </React.Fragment>
    );
}

function IconButton(props) {

  var className = 'icon-button';
  var classIcon = props.iconClass + ' icon';
  if(props.size == 'small') {
    classIcon += ' icon-small';
  }
  else if(props.size == 'medium') {
    classIcon += ' icon-medium';
  }

  return (
    <button className={className} 
      onClick={props.onClick}>
        <div className={classIcon}></div>
        { props.children }
    </button>
  );
}

export default Button;
export { IconButton };
