import './Benefits.css';
import React, { useState, useEffect } from 'react';
import Panel from '../../components/panel';
import BenefitsService from './BenefitsService';
import { useOutletContext } from 'react-router-dom';
import Skeleton from '../../components/skeleton';

function Benefits() {
  const [farmName] = useOutletContext();
  const [benefitsStatus, setBenefitsStatus] = useState(null);

  
  useEffect(() => {
    loadBenefits();
  }, [farmName]);

  const loadBenefits = () => {
    BenefitsService.getStatus()
      .then(response=> setBenefitsStatus(response));
  }

  return (
    <React.Fragment>
      <h1>Benefits</h1>
      <div className="title m-t-35">
          Year to Date
      </div>
      {!benefitsStatus && 
        <React.Fragment>
          <Skeleton className="m-t-20" height={81}></Skeleton>
          <Skeleton className="m-t-20" height={81}></Skeleton>
        </React.Fragment>
      }
      {benefitsStatus && 
        <div className="benefits-list">
          <Panel>
            <div className="benefit-item">
              <div className={"benefit-icon house-icon"}></div>
              <div className="value">{benefitsStatus.yearHome.toLocaleString()}</div>
              <div className="text">Homes’ electricity use for 1 year</div>
            </div>
          </Panel>
          <Panel>
            <div className="benefit-item">
              <div className={"benefit-icon car-icon"}></div>
              <div className="value">{benefitsStatus.yearMiles.toLocaleString()}</div>
              <div className="text">Miles driven by average gasoline powered car</div>
            </div>
          </Panel>
        </div>
      }
      <div className="title m-t-35">
          Life of Project
      </div>
      {!benefitsStatus && 
        <React.Fragment>
          <Skeleton className="m-t-20" height={81}></Skeleton>
          <Skeleton className="m-t-20" height={81}></Skeleton>
        </React.Fragment>
      }
      {benefitsStatus && 
        <div className="benefits-list">
          <Panel>
            <div className="benefit-item">
              <div className={"benefit-icon house-icon"}></div>
              <div className="value">{benefitsStatus.allTheTimeHome.toLocaleString()}</div>
              <div className="text">Homes’ electricity use for 1 year</div>
            </div>
          </Panel>
          <Panel>
            <div className="benefit-item">
              <div className={"benefit-icon car-icon"}></div>
              <div className="value">{benefitsStatus.allTheTimeMiles.toLocaleString()}</div>
              <div className="text">Miles driven by average gasoline powered car</div>
            </div>
          </Panel>
        </div>
      }
    </React.Fragment>
  );
}

export default Benefits;
