import './Table.css';
import React from 'react';

function TableHead(props) {
    return (
        <thead className="default-header">
            <tr>
            {props.columns &&
                props.columns.map((column, index) => 
                    <th key={index}>
                        {column.name}
                    </th>)
            }
            </tr>
        </thead>
    );
}

function TableRow(props) {
    return (<React.Fragment>
        <tr className="default-row">
            {props.data && props.columns &&
                props.columns.map((column, index) => 
                <td className="default-cell" key={index}>
                    {column.pipes ? column.pipes(props.data[column.fieldName]) : (props.data[column.fieldName] ?? 'N/A')}
                </td>)
            }
        </tr>
    </React.Fragment>);
}

function Table(props) {
    return (
        <React.Fragment>
            <table className="default">
                <TableHead columns={props.columns}></TableHead>
                <tbody>
                    {props.rows &&
                        props.rows.map((row, index) => <TableRow key={index} columns={props.columns} data={row}></TableRow>)
                    }
                </tbody>
            </table>
        </React.Fragment>
    );
}
  
  export default Table;