import './Documentation.css';
import React, { useState, useEffect, } from 'react';
import { useOutletContext } from 'react-router-dom';
import Input, { FileUpload } from "../../components/input";
import DocumentationService from "./DocumentationService";
import Panel from '../../components/panel';
import Button, { IconButton } from '../../components/button';
import Alert from '../../components/alert';
import { AdminTemplate } from '../../components/permission';
import FormAddFolder from './FormAddFolder';
import Auth from '../../utils/auth';
import Skeleton from '../../components/skeleton';
import { useDebounce } from '../../utils/hook';
import dayjs from 'dayjs';


function Documentation() {  
    const [farmName] = useOutletContext();
    const [documentTypeList, setDocumentTypeList] = useState(null);
    const [documentList, setDocumentList] = useState(null);
    const [activeFolderName, setActiveFolderName] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [alertSuccessUploadOpen, setAlertSuccessUploadOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [files, setFiles] = useState(null);
    const [openAlertSuccessFileDeleted, setOpenAlertSuccessFileDeleted] = useState(false);
    const [openAlertSuccessFolderDeleted, setOpenAlertSuccessFolderDeleted] = useState(false);
    const [loadingFile, setloadingFile] = useState(false);

    const callSearch = useDebounce(searchText, 500);

    useEffect(() => {
        loadFolder();
    }, [callSearch, farmName]);
  
    function handlerChangeSearchString(event) {
        setSearchText(event.target.value);
    }

    function handlerClickFolder(item) {
        if(item.name === activeFolderName) {
            return;
        }
        selectFolder(item.name);
    };

    function showAlert(message){
        setAlertOpen(true);
        setAlertMessage(message);
    }

    function loadFolder(setActiveFolder = true) {
        setDocumentList(null);
        setDocumentTypeList(null);
        return DocumentationService.getDocumentTypeList({
            fileName: searchText?.toLowerCase(),
        }).then(res => { 
            setDocumentTypeList(res);
            if(res.length > 0) {
                if(setActiveFolder || !res.some(item => item.name === activeFolderName)) {
                    selectFolder(res[0].name);
                }
            }
            else {
                setActiveFolderName(null);
                setDocumentList([]);
            }
        }).catch(error => {
            showAlert("Something went wrong, please reload the page and try again");
            setDocumentTypeList([]);
            setDocumentList([]);
        });
    }

    function loadFiles(folder) {
        setDocumentList(null);
        DocumentationService.getDocumentList(folder)
            .then(res => setDocumentList(res))
            .catch(error => { 
                showAlert("Something went wrong, please reload the page and try again");
                setDocumentList([]);
            });
    }

    function getFileList() {
        if(!searchText || !documentList) {
            return documentList;
        }
        return documentList.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
    }

    function selectFolder(folderName) {
        setActiveFolderName(folderName);
        loadFiles(folderName);
    }

    const handlerAddDocument = () => {
        var file = files[0];
        if(file){
            setloadingFile(true);
            DocumentationService.addDocument(activeFolderName, file)
                .then(responce => {
                    setFiles(null);
                    setloadingFile(false);
                    setAlertSuccessUploadOpen(true);
                    loadFiles(activeFolderName);
                })
                .catch(error => showAlert("File add has been failed"));
        }
        else {
            showAlert("Please choose any file");
        }
    }

    const handlerDeleteDocument = (folder, document) => {
        DocumentationService.deleteDocument(folder, document.name)
            .then(responce => { 
                loadFiles(activeFolderName);
                setOpenAlertSuccessFileDeleted(true);
            })
            .catch(error => showAlert("File removing has been failed"));
    }

    const handlerDeleteFolder = (folder) => {
        DocumentationService.getDocumentList(folder.name)
            .then(res => {
                if(res.length > 0) {
                    showAlert("Please remove all files in folder to delete");
                }
                else {
                    DocumentationService.deleteFolder(folder.name)
                    .then(response => {
                        loadFolder(activeFolderName == folder.name);
                        setOpenAlertSuccessFolderDeleted(true);
                    })
                    .catch(error => showAlert("Folder removing has been failed"));
                }
            })
            .catch(error => showAlert("Folder removing has been failed"));
    }

    const handlerGetDocument = ((activeFolder, fileName) => {
        DocumentationService.getDocument(activeFolder, fileName)
            .catch(error => showAlert("File download has been failed"));
    });

    const handlerSavedFolder = (event) => {
        loadFolder().then(response => {
            selectFolder(event.folderName);
        });
    }

    return (
    <React.Fragment>
        <div className="documentation-header">
            <div>
                <h1>Documentation</h1>
            </div>
            <div className="search-bar">
                <Input iconClass="search-icon" placeholder="Search" onChange={(e) => handlerChangeSearchString(e)} />
            </div>
        </div>
        <Alert className="m-t-15" isOpen={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage}</Alert>
        <Alert className="m-t-15" severity="success" isOpen={openAlertSuccessFolderDeleted} onClose={() => setOpenAlertSuccessFolderDeleted(false)}>Folder has been removed successfully</Alert>
        <div className="document-type-list">
            {documentTypeList === null &&
                [...Array(3).keys()].map((item, index) => 
                    <div key={index}>
                        <Skeleton width='373px' height='80px'></Skeleton>
                    </div>)
            }
            {documentTypeList &&
                documentTypeList.map((item, index) => 
                        <Panel key={index} className={'folder ' + (item.name == activeFolderName ? 'active' : '')}>
                            <div className="document-type" onClick={ () => { handlerClickFolder(item); } }>
                                <div className="document-type-icon"></div>
                                <div className="document-type-name">{item.name}</div>
                            </div>
                            <AdminTemplate>
                                <IconButton iconClass="bin-icon" size='medium' onClick={() => handlerDeleteFolder(item)}></IconButton>
                            </AdminTemplate>
                        </Panel>
                )
            }
            <AdminTemplate>
                <FormAddFolder farmName={Auth.getFarmName()} onSaved={(e) => handlerSavedFolder(e) }></FormAddFolder>
            </AdminTemplate>
        </div>
        <AdminTemplate>
            <Panel className="m-t-15">
                <Alert className="m-b-10" severity="success" isOpen={alertSuccessUploadOpen} onClose={() => setAlertSuccessUploadOpen(false)}>File has been uploaded successfully</Alert>
                <div className="document-upload">
                    <FileUpload files={files} 
                        disabled={activeFolderName == null}
                        accept=".docx,.pdf,.jpg,.xlsx"
                        onChange={(e, files) => setFiles(files)}></FileUpload>
                    <Button width="auto" className="document-add" 
                        loading={loadingFile} 
                        disabled={files == null} 
                        onClick={() => handlerAddDocument() }>Save File</Button>
                </div>
            </Panel>
        </AdminTemplate>
        <Alert className="m-t-15" severity="success" isOpen={openAlertSuccessFileDeleted} onClose={() => setOpenAlertSuccessFileDeleted(false)}>File has been removed successfully</Alert>
        <div className="document-list">
            {documentList === null &&
                [...Array(3).keys()].map((item, index) => 
                    <Skeleton key={index} className="m-t-15" width='100%' height='80px'></Skeleton>)
            }
            {documentList &&
                getFileList().map((item, index) => 
                    <Panel key={index}>
                        <div className="document">
                            <div className={item.extension + "-icon document-icon"}></div>
                            <div class="document-info">
                                <div className="document-name">{item.name}</div>
                                <div className="document-created">{dayjs(item.createdOn).format('MM/DD/YYYY HH:mm A')}</div>
                            </div>
                            <div className="document-button">
                                <Button text="Download" onClick={ () => handlerGetDocument(activeFolderName, item.name) }></Button>
                            </div>
                            <AdminTemplate>
                                <div className="document-button-delete">
                                    <IconButton iconClass="bin-icon" size='medium' onClick={() => handlerDeleteDocument(activeFolderName, item)}></IconButton>
                                </div>
                            </AdminTemplate>
                        </div>
                    </Panel>
                )
            }
            {searchText && documentTypeList?.length == 0 &&
                <div className="title grey-font m-t-15">No result found</div>
            }
        </div>
    </React.Fragment>
    );
}

export default Documentation;
