import React from "react";
import Auth from "../../utils/auth";

function AdminTemplate(props) {
    return (
        <React.Fragment>
          {Auth.isAdmin() && 
            props.children
          }
        </React.Fragment>
    );
}

export { AdminTemplate };
