import './Panel.css';

function Panel(props) {
    return (
        <div className={"panel " + (props.className ?? '') }
            onClick={props.onClick}>
          {props.children}
        </div>
    );
}

export default Panel;
