import { Skeleton as SkeletonMaterial } from "@mui/material";

function Skeleton(props) {
    return (
        <SkeletonMaterial
            className={props.className}
            sx={{ bgcolor: 'rgba(218, 233, 191, 0.4)' }}
            variant={props.variant ?? "rounded"}
            animation="wave" 
            width={props.width} 
            height={props.height} >
        </SkeletonMaterial>
    );
}
  
  export default Skeleton;