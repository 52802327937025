import './Layout.css';
import React, { useState } from 'react';
import Menu from '../menu';
import { Outlet } from "react-router-dom";
import Profile from '../profile';
import Auth from '../../utils/auth';

function Layout() {
    const [farmName, setFarmName] = useState(Auth.getFarmName());
    const handlerFarmChange = (e) => {
        setFarmName(e.farmName);
    }

    return (
        <div className="container">
            <div className="left-column">
                <div className="logo"></div>
                <Menu farmName={farmName}></Menu>
            </div>
            <div className="right-column">
                <div className="top-bar">
                    <Profile handlerFarmChange={handlerFarmChange}></Profile>
                </div>
                <div className="content m-b-40">
                    <Outlet context={[farmName]} />
                </div>
                <footer>
                    <div className="footer">
                        ⓒ 2023, LF Bioenergy
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Layout;
