import './Dashboard.css';
import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FeedStatus, 
  Hwce,
  Maintenance, 
  Compliance, 
  SkeletonCart 
} from './Components';
import Chart from './Chart';
import DashboardService from './DashboardService';
import Alert from '../../components/alert';
import Auth from '../../utils/auth';
import Modal from '../../components/modal';
import Button from '../../components/button';
import Loading from '../../components/loading';

function Dashboard() {
  const [farmName] = useOutletContext();
  const [feedstockQuality, setFeedstockQuality] = useState(null);
  const [feedstockVolume, setFeedstockVolume] = useState(null);
  const [productionStatus, setProuctionStatus] = useState(null);
  const [hwceStatus, setHwceStatus] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpenChart, setIsOpenChart] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartPeriodSelected, setChartPeriodSelected] = useState('Month');
  const [chartIsLoading, setChartIsLoading] = useState(false);
  const chartDataVisible = useMemo(() => changeChartData(chartData, chartPeriodSelected, chartIsLoading), [chartData, chartPeriodSelected, chartIsLoading]);

  function showAlert(message) {
    setAlertOpen(true);
    setAlertMessage(message);
  }

  useEffect(() => {
    setFeedstockQuality(null);
    setFeedstockVolume(null);
    setProuctionStatus(null);
    setHwceStatus(null);
    DashboardService.getFeedstockQualityStatus()
      .then(res => { 
        setFeedstockQuality(res);
      });
    DashboardService.getFeedstockVolumeStatus()
      .then(res => { 
        setFeedstockVolume(res);
      });
    DashboardService.getProductionStatus()
      .then(res => { 
        setProuctionStatus(res);
      });
    DashboardService.getHwceStatus()
      .then(res => { 
        setHwceStatus(res);
      });
  }, [farmName]);

  const handlerOpenFeedstockQualityChart = () => {
    setNewChartData(feedstockQuality.title, DashboardService.getFeedstockQualityChart);
  }

  const handlerOpenFeedstockVolumeChart = () => {
    setNewChartData(feedstockVolume.title, DashboardService.getFeedstockVolumeChart);
  }

  const handlerOpenProductionChart = () => {
    setNewChartData(productionStatus.title, DashboardService.getProductionChart);
  }

  const setNewChartData = (title, loader) => {
    setChartIsLoading(true);
    setChartData({
      title: title,
      values: null,
    });

    Promise.all([
      loader({
        ChartPeriod: "Month",
      }),
      loader({
        ChartPeriod: "HalfYear",
      }),
      loader({
        ChartPeriod: "Year",
      }),
    ])
      .then(([month, halfYear, year]) => {
        let min = Math.min(Math.min(...month.points), Math.min(...halfYear.points), Math.min(...year.points));
        let max = Math.max(Math.max(...month.points), Math.max(...halfYear.points), Math.max(...year.points));
        
        setChartData({
          title: title,
          values: {
            month, 
            halfYear, 
            year,
          },
          min: min - (min * 0.05),
          max: max + (max * 0.05),
        })
      })
      .finally(() => {
        setChartIsLoading(false);
      });

    setIsOpenChart(true);
  }

  const handlerCloseChart = () => {
    setIsOpenChart(false);
    setChartData(null);
    setChartPeriodSelected('Month');
  }

  const handlerChangePeriod = (value) => {
    setChartPeriodSelected(value);
  }

  return (
    <React.Fragment>
      <Alert className="m-t-15" isOpen={alertOpen} onClose={() => setAlertOpen(false)}>{alertMessage}</Alert>
      <h1>Feed Status</h1>
      <div className="m-t-5 text-under-title">All Measurements are Averages of Daily Readings</div>
      <div className="m-t-15 grid-carts">
        {feedstockQuality ?
          <FeedStatus item={feedstockQuality} 
            onOpenChart={handlerOpenFeedstockQualityChart}>
          </FeedStatus> 
          : <SkeletonCart></SkeletonCart>
        }
        {feedstockVolume ?
          <FeedStatus item={feedstockVolume} 
            onOpenChart={handlerOpenFeedstockVolumeChart}>
          </FeedStatus> 
          : <SkeletonCart></SkeletonCart>
        }
        {Auth.getFarm().type === 'Hub' && 
          (productionStatus ? 
            <FeedStatus item={productionStatus}
              onOpenChart={handlerOpenProductionChart}>
            </FeedStatus> 
            : <SkeletonCart></SkeletonCart>)
        }
      </div>
      <div className="title m-t-35">
        Herd Size
      </div>
      <div className="grid-carts">
        {hwceStatus ?
          <Hwce item={hwceStatus}></Hwce> 
            : <SkeletonCart></SkeletonCart>
        }
      </div>
      <Modal open={isOpenChart} title={chartData?.title} onClose={() => handlerCloseChart()}>
        <div className="chart-period-button">
          <div className="m-r-5">
            <Button text="1 Month" 
              active={chartPeriodSelected === "Month"} 
              onClick={ () => handlerChangePeriod("Month") }>
            </Button>
          </div>
          <div className="m-r-5">
            <Button text="6 Months" 
              className="m-r-5" 
              active={chartPeriodSelected === "HalfYear"} 
              onClick={ () => handlerChangePeriod("HalfYear") }>
            </Button>
          </div>
          <Button text="Year" 
            active={chartPeriodSelected === "Year"} 
            onClick={ () => handlerChangePeriod("Year") }>
          </Button>
        </div>
        <Chart loading={chartIsLoading} data={chartData ? chartDataVisible : null} options={{
          min: chartData?.min,
          max: chartData?.max,
        }}>
        </Chart>
      </Modal>
    </React.Fragment>
  );
}

const changeChartData = (data, period, isLoading) => {
  if(isLoading || !data) {
    return null;
  }
  switch(period) {
    case "Month":
      return data.values.month;
    case "HalfYear":
      return data.values.halfYear;
    case "Year":
      return data.values.year;
    default:
      return null;
  }
}

export default Dashboard;
