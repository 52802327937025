
import { unregister } from './httpInterceptor';

class HttpClient {

    static get(url, params) {
        url = window.config.apiUrl + encodeURI(url);
        if(params) {
            url += this.getQueryStringParams(params);
        }
        return fetch(url, {
            method: 'GET',
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
          });
    }   
    
    static post(url, body, params) {
        url = window.config.apiUrl + encodeURI(url);
        return fetch(url, {
            method: 'POST',
            body: body
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response;
        });
    }
    
    static delete(url, params) {
        url = window.config.apiUrl + encodeURI(url);
        return fetch(url, {
            method: 'DELETE'
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response;
        });
    }
    
    static getBlob(url, params) {
        url = window.config.apiUrl + encodeURI(url);
        return fetch(url, {
            method: 'GET',
            
        }).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.blob();
          });
    }

    static getQueryStringParams(params) {
        const encoded = Object.entries(params).filter(([k, v]) => v).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&");
        return '?' + encoded;
    }
}

export default HttpClient;