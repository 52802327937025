import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../../utils/auth";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 1000);
    }, []);

    return (
        <div></div>
    );
  }
  
  export default Logout;