import HttpClient from "../../utils/httpClient"
import Auth from "../../utils/auth";
import dayjs from 'dayjs';

class DashboardService {
    
    static getFeedstockQualityStatus() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/feedstockquality`)
            .then(response => {
                return {
                    title: 'Feedstock Quality',
                    icon: 'fire-background',
                    week: response.last7days,
                    month: response.lastMonth,
                    year: response.thisYear,
                    unit: 'Digestible Solids lbs',
                };
            });
    }
    
    static getFeedstockQualityChart(request) {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/feedStockQualityMonthly`, request)
            .then(response => {
                const data = response.monthlyData.reverse();
                return {
                    labels: data.map(x=> dateDayFormat(x.date)),
                    points: data.map(x=> x.data),
                }
            });
    }

    static getFeedstockVolumeStatus() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/feedstockvolume`)
            .then(response => {
                return {
                    title: 'Feedstock Volume',
                    icon: 'manure-background',
                    week: response.last7days,
                    month: response.lastMonth,
                    year: response.thisYear,
                    unit: 'Gallons',
                }
            });
    }

    static getFeedstockVolumeChart(request) {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/feedStockVolumeMonthly`, request)
            .then(response => {
                const data = response.monthlyData.reverse();
                return {
                    labels: data.map(x=> dateDayFormat(x.date)),
                    points: data.map(x=> x.data),
                }
            });
    }

    static getHwceStatus() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/hwce`)
            .then(response => {
                return {
                    title: 'HWCE',
                    icon: 'cow-background',
                    lastQuarter: response.lastQuarter,
                    prevQuarter: response.prevQuarter,
                    unit: '',
                }
            });
    }

    static getHwceTable() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/cows`);
    }

    static getHwceMonthly() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/hwceMonthly`)
                .then(response => {
                    const data = response.monthlyData.reverse();
                    return {
                        labels: data.map(x=> x.date),
                        points: data.map(x=> x.data),
                    }
                });
    }

    static getProductionStatus() {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/gasproduction`)
                .then(response => {
                    return {
                        title: 'RNG Production',
                        icon: 'fire-background',
                        week: response.last7days,
                        month: response.lastMonth,
                        year: response.thisYear,
                        unit: 'MMBTU',
                    }
                });
    }

    static getProductionChart(request) {
        return HttpClient.get(`site-api/farm/${Auth.getFarmName()}/gasProductionMonthly`, request)
                .then(response => {
                    const data = response.monthlyData.reverse();
                    return {
                        labels: data.map(x=> dateDayFormat(x.date)),
                        points: data.map(x=> x.data),
                    }
                });
    }

    static getMaintenance(farm) {

        return HttpClient.get(`site-api/maintenance`).then(response => {
            var maintenance = response.farmMaintenance.find(x => { 
                return  x.farmName.toLowerCase() === farm.toLowerCase() || x.farmName.toLowerCase().indexOf(' ' + farm.toLowerCase()) > 0
            });
 
            return {
                overallMaintenanceCompletion: maintenance.maintenance,
                plannedMaintenance: maintenance.planned,
                unplannedMaintenance: maintenance.unplanned,
            };
        });
    }

    static getCompliance() {
        return Promise.all([
            HttpClient.get(`site-api/training`),
            new Promise((resolve, reject) => { resolve(80) }),
            new Promise((resolve, reject) => { resolve(65) }),
        ]).then(([safetyTrainingCompletion, environmentalCompliance, annualSafetyObjective]) => {
            return {
                safetyTrainingCompletion: safetyTrainingCompletion,
                environmentalCompliance: environmentalCompliance,
                annualSafetyObjective: annualSafetyObjective,
            }
        })
    }
}

function dateDayFormat(date) {
    console.log(date, dayjs(date).format('MM/DD/YYYY'));
    return dayjs(date).format('MM/DD/YYYY');
}

export default DashboardService;