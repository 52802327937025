import './Dashboard.css';
import React, { useState, useEffect } from 'react';
import Button from '../../components/button';
import Panel from '../../components/panel';
import ProgressBar from '../../components/progressBar';
import Modal from '../../components/modal';
import { IconButton } from '../../components/button';
import dayjs from 'dayjs';
import Chart from './Chart';
import Skeleton from '../../components/skeleton';
import Table from '../../components/table';

var quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

function Cart(props) {
    return <Panel>
        <div className={'cart ' + (props.backgroundImage ? props.backgroundImage + ' cart-background ' : '')  }>
            {props.children}
        </div>
    </Panel>
}

var nowDate = dayjs(Date.now());
var prevMonthDate = dayjs(Date.now()).add(-1, 'month');
var lastWeek = dayjs(Date.now()).add(-6, 'day');
var lastQuarter = dayjs(Date.now()).add(-3, 'month');
var prevQuarter = dayjs(Date.now()).add(-6, 'month');


function FeedStatus(props) {
    let item = props.item ?? {};

    const handlerClickChart = () => {
        if(props.onOpenChart) {
            props.onOpenChart();
        }
    }

    return <React.Fragment>
        <Cart backgroundImage={item.icon}>
            <div className="cart-header">
                <div className="cart-title">
                    <div>{item.title}</div>
                    <div className="text-under-title">({item.unit})</div>
                </div>
                <div className="cart-diagram">
                    <IconButton iconClass="chart-icon" onClick={() => handlerClickChart()}></IconButton>
                </div>
            </div>
            <div className="cart-row">
                <div className="cart-period">
                    <div className="cart-text">Last 7 Days</div>
                    <div className="period-description">({lastWeek.format('MMM DD, YYYY') + ' - ' + nowDate.format('MMM DD, YYYY')})</div>
                </div>
                <div className="cart-value">{ item.week !== null && item.week != undefined ? item.week?.toLocaleString() : 'N/A'}</div>
            </div>
            <div className="cart-row">
                <div className="cart-period">
                    <div className="cart-text">Last Month</div>
                    <div className="period-description">({prevMonthDate.format('MMMM YYYY')})</div>
                </div>
                <div className="cart-value">{ item.month !== null && item.month != undefined ? item.month?.toLocaleString() : 'N/A'}</div>
            </div>
            <div className="cart-row">
                <div className="cart-period">
                    <div className="cart-text">This Year</div>
                    <div className="period-description">({nowDate.format('YYYY')})</div>
                </div>
                <div className="cart-value">{ item.year !== null && item.year != undefined ? item.year?.toLocaleString() : 'N/A'}</div>
            </div>
        </Cart>
    </React.Fragment>;
};

function Hwce(props) {
    let item = props.item ?? {};
    const [isOpenChart, setIsOpenChart] = useState(false);
    const [isOpenTable, setIsOpenTable] = useState(false);

    const columns = [
        {
          name: 'Date',
          fieldName: 'recordDate',
          pipes: function(value) {
            const result = dayjs(value);
            return result.format('MM-YYYY');
          },
        },
        {
          name: 'HWCE',
          fieldName: 'hwce',
        },
        {
          name: 'Lactating Holsteins',
          fieldName: 'lactactingHolsteins',
        },
        {
          name: 'Dry Holsteins',
          fieldName: 'dryHolesteins',
        },
        {
          name: 'Holstein Heifers',
          fieldName: 'holesteinHeifers',
        },
        {
          name: 'Lactating Jerseys',
          fieldName: 'lactatingJerseys',
        },
        {
          name: 'Dry Jerseys',
          fieldName: 'dryJerseys',
        },
        {
          name: 'Jersey Heifers',
          fieldName: 'jerseyHeifers',
        },
    ];

    return <React.Fragment>
        <Cart backgroundImage={item.icon}>
            <div className="cart-header">
                <div className="cart-title">
                    <span className="title-pointer" onClick={() => setIsOpenTable(true)}>{item.title}</span>
                </div>
                <div className="cart-diagram">
                    {false && <IconButton iconClass="chart-icon" onClick={() => setIsOpenChart(true)}></IconButton>}
                </div>
            </div>
            <div className="cart-row m-t-25">
                <div className="cart-period">
                    <div className="cart-text">Last Quarter</div>
                    <div className="period-description">(Q{ lastQuarter.quarter() + ' ' + lastQuarter.format('YYYY')})</div>
                </div>
                <div className="cart-value">
                    {item.lastQuarter === 0 ? 'TBD' :
                        item.lastQuarter !== null && item.lastQuarter != undefined ? 
                         (item.lastQuarter?.toLocaleString() + ' ' + item.unit) : 'N/A'
                    }
                </div>
            </div>
            <div className="cart-row">
                <div className="cart-period">
                    <div className="cart-text">Previous Quarter</div>
                    <div className="period-description">(Q{ prevQuarter.quarter() + ' ' + prevQuarter.format('YYYY')})</div>
                </div>
                <div className="cart-value">
                    {item.prevQuarter === 0 ? 'TBD' :
                        item.prevQuarter !== null && item.prevQuarter != undefined ? 
                            (item.prevQuarter?.toLocaleString() + ' ' + item.unit) : 'N/A'
                    }
                </div>
            </div>
        </Cart>
        <Modal open={isOpenTable} title={item.title} onClose={() => setIsOpenTable(false)}>
            <Table columns={columns} rows={props.tableData}>
            </Table>
        </Modal>
        <Modal open={isOpenChart} title={item.title} onClose={() => setIsOpenChart(false)}>
            <Chart data={props.chartData}></Chart>
        </Modal>
    </React.Fragment>;
};

function Maintenance(props) {
    let item = props.item ?? {};

    return <Cart>
        <div className="cart-header">
            <div className="cart-title">Maintenance</div>
        </div>
        <div className="cart-row full-size">
            <div className="cart-text flex-default">Overall Maintenance Completion</div>
            <div className="cart-value-percent">{item.overallMaintenanceCompletion !== null ? (item.overallMaintenanceCompletion + ' %') : 'N/A'}</div>
        </div>
        <div className="cart-row line-bar">
            <ProgressBar value={item.overallMaintenanceCompletion ?? 0} />
        </div>
        <div className="cart-row">
            <div className="cart-row-2-column">
                <div>
                    <div className="cart-text">Planned Maintenance</div>
                    <div className="cart-value-percent">{item.plannedMaintenance !== null ? (item.plannedMaintenance + ' %') : 'N/A'}</div>
                </div>
                <div className="text-right">
                    <div className="cart-text">Unplanned Maintenance</div>
                    <div className="cart-value-percent">{item.unplannedMaintenance !== null ? (item.unplannedMaintenance + ' %') : 'N/A'}</div>
                </div>
            </div>
        </div>
        <div className="m-t-30">
            <Button text={'View Planned Outages'}></Button>
        </div>
    </Cart>
};

function Compliance(props) {
    let item = props.item ?? {};

    return <Cart>
        <div className="cart-header">
            <div className="cart-title">Compliance</div>
        </div>
        <div className="cart-row full-size">
            <div className="cart-text flex-default">Safety Training Completion</div>
            <div className="cart-value-percent">{item.safetyTrainingCompletion !== null ? (item.safetyTrainingCompletion + ' %') : 'N/A'}</div>
        </div>
        <div className="cart-row line-bar">
            <ProgressBar value={item.safetyTrainingCompletion !== null ? item.safetyTrainingCompletion : 'N/A'} />
        </div>
        <div className="cart-row">
            <div className="cart-row-2-column status">
                <div>
                    <div className="cart-text">Environmental Compliance</div>
                    <div className="cart-value-percent">{item.environmentalCompliance !== null ? item.environmentalCompliance : 'N/A'}</div>
                </div>
                <div className="status-flex">
                    <div className="status-bar">
                        <div className="grey"></div>
                        <div className="grey"></div>
                        <div className="green"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="cart-row">
            <div className="cart-row-column">
                <div className="cart-text">Annual Safety Objective (JHAs and Audits)</div>
                <div className="cart-value-percent">{item.annualSafetyObjective !== null ? (item.annualSafetyObjective + ' %') : 'N/A'}</div>
            </div>
        </div>
    </Cart>
};

function SkeletonCart() {
    return (
        <Skeleton height="252px">
        </Skeleton>
    )
}

export { 
    FeedStatus, 
    Hwce,
    Maintenance, 
    Compliance, 
    SkeletonCart,
};
