import React, { useState } from "react";
import Modal from '../../components/modal';
import { Form, FormRow, FormLabel, FormInput, FormActionButton, FormValidator } from '../../components/form';
import Input from "../../components/input";
import DocumentationService from "./DocumentationService";
import Button from '../../components/button';
import Auth from '../../utils/auth';
import Alert from "../../components/alert";

function FormAddFolder(props) {
    const [isOpenPopupAddFolder, setIsOpenPopupAddFolder] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [requiredFieldOpen, setRequiredFieldOpen] = useState(false);
    const [form, setForm] = useState({});
    const [formValidator, setValidator] = useState(FormValidator.init());

    function showAlert(message) {
        setAlertOpen(true);
        setAlertMessage(message);
    }

    var handlerOpenPopup = () => {
        setIsOpenPopupAddFolder(true);
    }

    const handlerAddFolder = () => {
        FormValidator.startValidation(formValidator, setValidator);
        
        if(!FormValidator.isValid(formValidator)) {
            setRequiredFieldOpen(true);
            return;
        }
        DocumentationService.addFolder(form.folderName)
        .then(response => {
            setIsOpenPopupAddFolder(false);
            clearForm();
            if(props.onSaved) {
                props.onSaved({
                    folderName: form.folderName,
                });
            }
        })
        .catch(error => showAlert("Folder add has been failed"));
    }

    const handlerChangeForm = (e) => {
        const newForm = Object.assign({}, form);
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handlerValidate = (e) => {
        FormValidator.setField(formValidator, e, setValidator);
    }

    const handlerClosePopup = () => {
        setIsOpenPopupAddFolder(false);
        clearForm();
    }

    const clearForm = () => {
        setValidator(FormValidator.init());
        setForm({});
        setAlertOpen(false);
        setRequiredFieldOpen(false);
    }
  
    return (
    <div>
        <Button className="document-add" onClick={() => handlerOpenPopup() }>Add Folder</Button>
        <Modal open={isOpenPopupAddFolder} onClose={() => handlerClosePopup() } showCloseButton={false}>
            <Alert className="m-b-10" isOpen={alertOpen} onClose={() => setAlertOpen(false) }>{alertMessage}</Alert>
            <Alert className="m-b-10" isOpen={requiredFieldOpen} onClose={() => setRequiredFieldOpen(false) }>Please fill out all required fields</Alert>
            <Form className="form-add-folder">
                <FormRow>
                    <FormLabel>Folder name</FormLabel>
                    <FormInput>
                        <Input placeholder="Folder name" 
                            name="folderName"
                            validation={formValidator}
                            onValidate={(e) => handlerValidate(e)}
                            onChange={(e) => handlerChangeForm(e)}></Input>
                    </FormInput>
                </FormRow>
                <FormActionButton>
                    <Button text="Add" onClick={() => handlerAddFolder() }></Button>
                    <Button text="Cancel" type="danger" onClick={() => handlerClosePopup() }></Button>
                </FormActionButton>
            </Form>
        </Modal>
    </div>
    );
}

export default FormAddFolder;